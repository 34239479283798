$titleText-color: white;

.App, html{
    height: 100vh;
    padding: 0;
    margin: 0;
    border:none;
}
h1{
    padding: 0;
    margin:0;
}
.title{
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    color: $titleText-color;
    text-shadow: 1px 1px 4px #00000099;
}